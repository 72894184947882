import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { userInformation } from '@redux/actions/User';
import { TokenClaims } from 'hooks/ui/useTokenClaims';
import useUser from 'hooks/ui/useUser';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/user';
import showRequestError from 'helpers/layout/showRequestError';
import getUserType from 'utils/getUserType';

type Options = {
  alwaysEnabled?: boolean;
};

export function useCurrentUserInfo(options?: Options) {
  const dispatch = useDispatch();
  const user = useUser();
  const queryClient = useQueryClient();
  const { userIsInvestor } = getUserType(user);

  return useQuery({
    queryKey: ['current-user-info'],
    queryFn: async () => {
      try {
        const response = await axios.get<
          ApiResponse<Api.GetCurrentUserResponse>
        >(`user/current-user-info`);
        const account = response.data.data;

        // Check if the user in the token has changed from the user stored in
        // the redux state. This will happen when a delegate switches accounts
        // or admin logs in as a delegate.
        if (user._id !== account._id) {
          const tokenClaims: TokenClaims = {
            isDelegate: false,
          };

          if (account.delegatePermittedFirmId && account.delegateAccessLevel) {
            tokenClaims.isDelegate = true;
            tokenClaims.delegateAccessLevel = account.delegateAccessLevel;
            tokenClaims.delegatePermittedFirmId =
              account.delegatePermittedFirmId;
            tokenClaims.principalInvestor = account.principalInvestor;
            tokenClaims.isDelegateOfAllOfInvestorsInvestments =
              account.isDelegateOfAllOfInvestorsInvestments;
          }

          dispatch<any>(
            userInformation({
              ...account,
              // in this app, we only ever use this token to determine if the
              // user is logged in or not, so it doesn't matter what the value is
              // The auth token is actually sent to the server in the Authorisation header.
              token: 'token',
              tokenClaims,
            }),
          );

          // clear the cache so the latest user data is always used
          queryClient.clear();
        }

        return account;
      } catch (err) {
        showRequestError(err);
      }
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: false,
    enabled:
      options?.alwaysEnabled || (!!user._id && !!user.token && userIsInvestor),
  });
}
